/**
 *
 */

.hero {
  background-color: var(--xs-hero-background);
  background-image: url(/assets/dist/images/hero-bg.jpg);
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 760px 504px;
  color: var(--xs-hero-color);

  @media (min-width: 768px) {
    background-color: var(--sm-hero-background);
  }

  @media (max-width: 991px) {
    text-align: center;
    background: none;
  }

  @media (min-width: 992px) {
    background-color: var(--md-hero-background);
    color: var(--md-hero-color);
  }

  .container {
    @media (max-width: 991px) {
      background-color: var(--sm-hero-container-background);
    }

    @media (min-width: 992px) {
      background-color: var(--md-hero-container-background);
      padding: 0;

      & > .row {
        display: table;
        width: 100%;
        margin: 0;
      }
    }
  }

  .col-md-8 {
    line-height: 22px;

    a {
      color: var(--xs-hero-color);

      @media (min-width: 992px) {
        color: var(--md-hero-color);
      }
    }

    strong {
      font-size: 21px;
      line-height: 24px;
      margin: 13px 10px 12px;
      display: block;
      font-weight: 600;
      widows: 2;

      @media (min-width: 768px) {
        font-size: 26px;
        font-weight: 600;
        margin: 18px 10px;
        line-height: 36px;
      }

      @media (min-width: 992px) {
        margin: 16px 0;
      }

      @media (min-width: 1200px) {
        font-weight: bold;
        margin: 14px 0 24px;
        font-size: 36px;
        line-height: 44px;
      }
    }

    @media (min-width: 992px) {
      padding: 20px 30px 30px;
      display: table-cell;
      float: none;
    }
  }

  h1 {
    font-size: 22px;
    font-weight: 600;
    margin: 23px 0 16px;

    @media (min-width: 992px) {
      margin-top: 16px;
    }

    @media (min-width: 1200px) {
      font-size: 26px;
    }
  }

  p {
    font-size: 16px;
    line-height: 26px;
    min-height: 1em;
  }
}
