/**
 *
 */

.cta {
  background: var(--xs-cta-background);
  color: var(--xs-cta-color);
  font-size: 18px;
  font-weight: 500;
  padding: 0 15px 15px;

  @media (min-width: 768px) {
    padding: 4px 0;
  }

  @media (max-width: 992px) {
    background: var(--primary);
  }
  
  @media (min-width: 992px) {
    border-left: 9px solid transparent;
    padding: 35px 20px 25px;
    display: table-cell;
    float: none;
    vertical-align: middle;
  }

  @media (min-width: 1200px) {
    border-left-width: 18px;
    padding: 35px 50px 25px;
  }

  strong {
    font-size: 21px;
    line-height: 24px;
    font-weight: 600;
    display: block;
    margin: 14px 0 0;

    @media (min-width: 768px) {
      font-size: 26px;
    }

    @media (min-width: 992px) {
      font-size: 28px;
      line-height: 36px;
      margin: 3px 0 6px;
    }
  }

  p {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 0;

    @media (min-width: 768px) {
      padding-top: 2px;
      font-size: 16px;
    }

    @media (min-width: 992px) {
      margin-bottom: 20px;
    }
  }

  .btn {
    background: var(--xs-button-background);
    border: 2px solid;
    border-radius: 10px;
    color: var(--xs-button-color);
    padding: 5px 36px;
    font-size: 18px;
    font-weight: bold;

    @media (min-width: 768px) {
      margin: 8px 0 16px;
      padding: 8px 40px;
    }

   @media (max-width: 992px) {
     background: var(--secondary-lightest);
     color: var(--contrast);
     
     &:hover,
     &:active,
     &:focus {
       background: var(--secondary-lightest);
       color: var(--contrast);      
     }
   }


  }
}
