:root {
  --btn-width: 56px;
}

#location-search {
  padding: 16px 15px;
  background: var(--xs-search-background);

  @media (min-width: 768px) {
    padding: 20px 15px 20px;
    background: var(--sm-search-background);
    min-height: 92px;
  }

  @media (min-width: 992px) {
    padding: 20px 30px 20px;
  }

  @media (min-width: 1200px) {
    padding: 20px 112px 20px;
  }

  .mapboxgl-ctrl-geocoder {
    border-radius: 0;
    box-shadow: none;
    text-align: left;
    position: relative;
    width: 100%;
    max-width: none;
    min-width: 0;

    &::before {
      display: none;
      line-height: 50px;
      font-size: 16px;
      color: var(--sm-search-color);
      position: absolute;
      text-align: center;
      font-weight: 500;
      width: 144px;
      left: -144px;
    }

    &::after {
      clear: both;
      content: " ";
      display: block;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      max-width: 450px;
      margin: 0 auto;
    }

    input {
      height: 50px;
      font-size: 13px;
      font-family: 'Verdana';
      padding: 0 15px;
      width: calc(100% - var(--btn-width));
      display: block;
      float: left;
    }

    .geocoder-pin-right {
      display: none;
    }

    .geocoder-pin-far-right {
      width: var(--btn-width);
      display: block;
      float: left;
    }

    & > div {
      display: block;
      float: left;
    }
  }

  .btn-search {
    background: var(--xs-search-button-background);
    color: var(--xs-search-button-color);
    border: 1px solid;
    width: var(--btn-width);
    font-size: 20px;
    font-family: OpenSans, 'Open Sans', Arial, sans-serif;
    line-height: 50px;
    font-weight: bold;
  }
}

.location {
  #location-search {
    @media (min-width: 768px) {
      padding: 21px;
    }

    .mapboxgl-ctrl-geocoder {
      @media (min-width: 768px) {
        max-width: none;
        left: 0;

        &::before {
          content: none;
        }
      }
    }
  }
}
