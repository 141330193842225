/**
 *
 */

.map {
  background: var(--xs-map-background);

  h2 {
    margin: 0 -15px;
    line-height: 1;
    font-size: 16px;
    padding: 0;

    @media (max-width: 991px) {
      padding: 0;
      margin: 0;
    }

    a {
      background: var(--sm-map-anchor-background);
      color: var(--sm-map-anchor-color);
      display: block;
      font-size: 16px;
      line-height: 24px;
      padding: 11px 30px;

      @media (max-width: 767px) {
        padding: 17px 15px;
      }

      .caret {
        color: var(--sm-links-caret-color);

        @media (max-width: 767px) {
          color: var(--xs-drawer-color);
        }
      }
    }
  }

  @media (min-width: 768px) {
    background: var(--sm-map-background);
  }

  @media (min-width: 992px) {
    background: var(--md-map-background);
  }

  .container {
    @media (min-width: 768px) and (max-width: 991px) {
      padding-bottom: 25px;
    }

    background: var(--sm-map-container-background);
  }
}

#map {
  & > .row {
    position: relative;

    #start-geolocate {
      background-color: var(--secondary-darker);
      background-image: url(/assets/dist/images/map-blurred.jpg);

      .location-container {
        background: rgba(255, 255, 255, 0.5);
        display: table;
        width: 100%;
        height: 450px;

        @media (min-width: 320px) and (max-width: 767px) {
          height: 300px;
        }

        @media (min-width: 768px) and (max-width: 1199px) {
          height: 450px;
        }
      }

      .location-center {
        display: table-cell;
        padding-top: 30px;
        vertical-align: middle;

        @media (min-width: 320px) and (max-width: 767px) {
          text-align: center;
          padding: 15px 0;
          vertical-align: top;
        }

        .geolocate-loading-icon {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;

          .loader-img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 50px;
            height: 50px;
            margin: -25px 0 0 -25px;
          }
        }

        .geolocate-error {
          width: 720px;
          background: none;
          margin: 0 auto 15px;

          @media (min-width: 320px) and (max-width: 767px) {
            padding: 10px;
            width: 300px;
          }

          .geolocate-error-wrapper {
            padding: 24px 20px;
            border-top: 1px solid var(--contrast);
            border-bottom: 1px solid var(--contrast);
            font-size: 20px;
            line-height: 27px;
            font-weight: 700;
            color: var(--text-darkest);
          }
        }
      }

      .geolocate-input {
        width: 720px;
        background: var(--secondary-lightest);
        margin: 0 auto 15px;
        padding: 40px 70px;
        text-align: left;

        @media (min-width: 320px) and (max-width: 767px) {
          padding: 10px;
          width: 300px;
        }

        @media (min-width: 768px) and (max-width: 991px) {
          padding: 40px 55px;
        }

        &::after {
          content: " ";
          display: block;
          clear: both;
        }

        .start-label {
          font-size: 18px;
          font-weight: 700;
          line-height: 24px;
          color: var(--text);
          margin-bottom: 25px;

          @media (min-width: 320px) and (max-width: 767px) {
            text-align: center;
          }
        }

        .location-holder {
          #start-geocoder {
            display: block;
            float: left;
            margin-left: 25px;
            padding-left: 25px;
            border-left: 1px solid #ccc;
            
            @media (min-width: 320px) and (max-width: 767px) {
              width: 100%;
              float: none;
              margin-left: 0;
              padding-left: 0;
            }

            .mapboxgl-ctrl-geocoder {
              max-width: none;
              background: none;
              width: auto;
              box-shadow: unset;

              &::after {
                content: " ";
                display: block;
                clear: both;
              }

              input {
                display: block;
                float: left;
                background-color: var(--secondary-lightest);
                border: 1px solid #ccc;
                height: 50px;
                width: 240px;
                padding: 18px 15px;
                font-size: 14px;
                line-height: 17px;

                &::placeholder {
                  color: #bbb;
                }

                @media (min-width: 320px) and (max-width: 767px) {
                  width: 217px;
                }

                @media (min-width: 768px) and (max-width: 991px) {
                  width: 260px;
                }
              }

              ul.suggestions {
                top: 50px;
              }

              & > div {
                display: block;
                float: left;

                .geocoder-pin-right {
                  display: none;
                }

                .btn-search {
                  display: block;
                  height: 50px;
                  background-color: var(--contrast);
                  color: var(--text-lightest);
                  padding: 14px;
                  border: 1px solid #fff;
                  font-size: 20px;
                  line-height: 1;
                  font-weight: 700;
                }
              }
            }
          }

          .start-separator {
            display: none;
            float: left;
            padding: 10px 0;

            @media (min-width: 320px) and (max-width: 767px) {
              display: none;
            }

            &::before {
              content: "OR";
              padding: 0 35px;
              color: var(--text-lightest);
              font-size: 20px;
              line-height: 28px;
              font-weight: 700;
            }
          }

          a.btn {
            display: block;
            float: left;
            font-size: 14px;
            font-weight: 700;
            line-height: 1;
            background-color: var(--contrast);
            padding: 18px;
            border: 1px solid #fff;
            border-radius: 0;

            @media (min-width: 320px) and (max-width: 767px) {
              margin: 20px auto;
              float: none;
              width: 140px;
            }

            &::before {
              content: "\f124";
              font: normal normal normal 19px/14px FontAwesome !important;
              font-size: inherit;
              text-rendering: auto;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              margin: 0 5px 0 0;
            }
          }
        }
      }
    }
  }

  .locator-initialized {
    display: none;
  }

  @media (max-width: 767px) {
    display: block;
  }
}

#map-panel-holder {
  @media (max-width: 767px) {
    padding: 0;
  }

  @media (min-width: 992px) {
    padding: 0;
    width: 500px;
  }

  @media (min-width: 1200px) {
    width: 670px;
  }

  &.collapse {
    @media (min-width: 768px) {
      display: block;
    }
  }
}

#location-map {
  background-color: var(--xs-mapbox-background);
  height: 240px;

  @media (min-width: 768px) {
    height: 337px;
  }

  @media (min-width: 992px) {
    height: 548px;
  }

  .mapboxgl-ctrl-compass {
    display: none;
  }
}

.mapboxgl-popup-content {
  padding: 15px 35px 5px;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    span {
      padding-left: 3px;
    }
  }
}