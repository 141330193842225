/*
 * Custom CSS for client specific overrides
 */


/*
*assets/src/css/body-cta.css
*/
.body-cta {
	padding: 50px 0;
	@media (min-width: 320px) and (max-width: 767px) {
		text-align: center;
		border-top: 1px solid #fff;
		padding: 20px 0;
	}
	@media (min-width: 768px) {
		color: var(--sm-body-cta-color);
	}
	.container {
		padding: 0 30px;
		@media (min-width: 320px) and (max-width: 767px) {
			padding: 0 15px;
		}
	}
	h2 {
		margin: 0 0 10px;
	}
	p {
		a {
			color: var(--xs-body-cta-color);
			@media (min-width: 768px) {
				color: var(--sm-body-cta-color);
			}
		}
	}
	.btn-danger {
		padding: 20px 28px;
		background: var(--secondary-lightest);
		color: var(--contrast);
		&:hover,&:focus,&:active {
			background: var(--secondary-lightest);
		}
	}
}

/*
*assets/src/css/content.css
*/
#content {
	@media (max-width: 767px) {
		padding: 3px 0 10px 10px;
	}
	@media (min-width: 768px) {
		padding-bottom: 10px;
		font-size: 15px;
		line-height: 24px;
	}
}
.content {
	line-height: 21px;
	color: var(--sm-content-color);
	@media (min-width: 768px) {
		background: var(--sm-content-background);
		line-height: 24px;
	}
	@media (min-width: 992px) {
		background: var(--md-content-background);
	}
	.container {
		@media (min-width: 768px) {
			padding: 4px 15px 3px;
			background: var(--sm-content-container-background);
		}
		@media (min-width: 992px) {
			background: var(--md-content-container-background);
			padding: 17px 30px 4px;
		}
		@media (min-width: 1200px) {
			padding: 21px 30px 4px;
		}
	}
	h1,
  h2 {
		color: var(--xs-content-heading-color);
		font-size: 18px;
		font-weight: 600;
		line-height: 20px;
		margin: 0;
		padding: 18px 0 10px;
		@media (min-width: 768px) {
			margin: 0;
			padding-bottom: 12px;
			line-height: 28px;
			font-size: 26px;
			font-weight: 600;
		}
		@media (min-width: 992px) {
			padding-bottom: 14px;
		}
	}
	h2 {
		color: var(--text);
		@media (max-width: 991px) {
			padding-top: 0;
		}
		@media (max-width: 767px) {
			padding-top: 8px;
		}
	}
	p {
		span.btn-holder {
			display: block;
		}
		@media (min-width: 768px) {
			margin: 0;
			padding-bottom: 23px;
			img {
				float: left;
				margin-right: 30px;
			}
		}
		@media (min-width: 992px) {
			line-height: 26px;
			font-size: 16px;
		}
	}
	.img-col {
		img {
			display: block;
		}
	}
	.btn.btn-danger {
		font-weight: 700;
		margin-top: 10px;
		padding: 11px 28px;
	}
}

/*
*assets/src/css/cta.css
*/
.header {
	.cta {
		background: var(--xs-cta-background);
		color: var(--xs-cta-color);
		font-size: 18px;
		font-weight: 500;
		padding: 0 15px 15px;
		@media (min-width: 768px) {
			padding: 4px 0;
		}
		@media (max-width: 992px) {
			background: var(--primary);
		}
		@media (min-width: 992px) {
			border-left: 9px solid transparent;
			padding: 35px 20px 25px;
			display: table-cell;
			float: none;
			vertical-align: middle;
		}
		@media (min-width: 1200px) {
			border-left-width: 18px;
			padding: 35px 50px 25px;
		}
		strong {
			font-size: 21px;
			font-weight: 600;
			display: block;
			margin: 14px 0 14px;
			@media (min-width: 768px) {
				font-size: 26px;
			}
			@media (min-width: 992px) {
				font-size: 28px;
				line-height: 36px;
				margin: 3px 0 6px;
			}
		}
		p {
			font-size: 16px;
			line-height: 22px;
			margin-bottom: 0;
			@media (min-width: 768px) {
				padding-top: 2px;
				font-size: 16px;
			}
			@media (min-width: 992px) {
				margin-bottom: 20px;
			}
		}
		.btn {
			background: var(--xs-button-background);
			border: 2px solid;
			border-radius: 10px;
			color: var(--xs-button-color);
			padding: 5px 36px;
			font-size: 18px;
			font-weight: bold;
			@media (min-width: 768px) {
				margin: 8px 0 16px;
				padding: 8px 40px;
			}
			@media (max-width: 992px) {
				background: var(--secondary-lightest);
				color: var(--contrast);
				&:hover,
     &:active,
     &:focus {
					background: var(--secondary-lightest);
					color: var(--contrast);
				}
			}
		}
	}
}

/*
*assets/src/css/footer.css
*/
.footer {
	color: var(--xs-footer-color);
	@media (min-width: 992px) {
		background: var(--md-footer-background);
	}
	.container {
		@media (min-width: 992px) {
			background: var(--md-footer-container-background);
			padding: 0 30px;
		}
	}
	.container:first-child {
		@media (min-width: 992px) {
			padding-top: 15px;
		}
	}
	.container:last-child {
		padding-bottom: 15px;
		@media (min-width: 768px) {
			padding-bottom: 25px;
		}
		@media (min-width: 992px) {
			padding-bottom: 45px;
		}
	}
	.nav > li {
		a {
			color: var(--xs-link-color);
			padding: 10px 8px;
		}
		&.divider {
			display: inline;
			white-space: normal;
			background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAPCAYAAAAlH6X5AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyppVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTMyIDc5LjE1OTI4NCwgMjAxNi8wNC8xOS0xMzoxMzo0MCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUuNSAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDoyRThDNjA4NzAzNzgxMUU3OTNGNEFEM0EwMUY0MzIzQiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDoyRThDNjA4ODAzNzgxMUU3OTNGNEFEM0EwMUY0MzIzQiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjJFOEM2MDg1MDM3ODExRTc5M0Y0QUQzQTAxRjQzMjNCIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjJFOEM2MDg2MDM3ODExRTc5M0Y0QUQzQTAxRjQzMjNCIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+E9zeogAAABpJREFUeNpiWLly5QEmBgYGexDBQAFxECDAANs3A9hrz7agAAAAAElFTkSuQmCC');
			background-position: center center;
			background-repeat: no-repeat;
			padding: 0 9px;
		}
	}
	.navbar-nav {
		@media (max-width: 767px) {
			margin-top: 11px;
		}
		@media (max-width: 991px) {
			margin-top: 0;
			float: none !important;
		}
		@media (min-width: 992px) {
			margin-top: 12px;
			clear: left;
		}
		> li {
			a {
				@media (min-width: 768px) {
					text-decoration: none;
				}
				@media (min-width: 992px) {
					padding: 9px 0;
					font-size: 16px;
					line-height: 24px;
				}
			}
		}
	}
	.col-md-5 {
		.navbar-nav {
			@media (max-width: 767px) {
				margin-top: 5px;
			}
		}
	}
	.navbar-right {
		margin-bottom: 0;
		@media (min-width: 768px) and (max-width: 991px) {
			margin: 15px 0 6px;
			float: none;
		}
		@media (min-width: 992px) {
			padding: 9px 0 0 11px;
		}
		@media (min-width: 1200px) {
			padding: 9px 0 0;
		}
		> li {
			a {
				padding: 14px 16px;
				color: var(--xs-footer-color);
				font-size: 34px;
				@media (min-width: 768px) {
					padding: 14px 22px;
				}
				@media (min-width: 992px) {
					padding: 14px 19px;
				}
			}
		}
	}
	.navbar-text {
		font-size: 13px;
		line-height: 20px;
		margin-top: 12px;
		@media (max-width: 991px) {
			float: none;
			margin-top: 15px;
		}
		@media (min-width: 992px) {
			margin: 13px 0;
		}
	}
}

/*
*assets/src/css/header.css
*/
.top {
	background: var(--xs-header-background);
	border: 0;
	margin-bottom: 0;
	@media (min-width: 768px) {
		border-radius: 0;
		background: var(--sm-header-background);
	}
	@media (min-width: 992px) {
		background: var(--md-header-background);
	}
	.container {
		padding-bottom: 12px;
		@media (max-width: 767px) {
			padding-bottom: 0px;
		}
		@media (min-width: 768px) {
			background: var(--sm-header-container-background);
		}
		@media (min-width: 992px) {
			background: var(--md-header-container-background);
			padding-bottom: 4px;
		}
	}
	.navbar-toggle {
		z-index: 2;
		float: left;
		border-radius: 0;
		margin: 0;
		padding: 10px 12px 11px;
		border: 0;
		.icon-bar {
			height: 3px;
			width: 25px;
		}
	}
	.navbar-header {
		@media (max-width: 991px) {
			float: none;
			@media (min-width: 768px) {
				padding: 16px 0 10px;
			}
		}
		.navbar-brand {
			background: url(/assets/dist/images/logo.png) no-repeat center center;
			background-size: contain;
			height: 74px;
			width: 260px;
			@media (max-width: 991px) {
				margin: 4px auto 1px;
				float: none;
				display: block;
			}
			@media (max-width: 767px) {
				max-height: 62px;
				background-position: center 60%;
			}
			@media (min-width: 992px) {
				margin: 20px 0;
			}
			@media (min-width: 1200px) {
				margin: 20px 0px;
			}
		}
	}
	.navbar-right {
		padding: 52px 30px 0 0;
		@media (max-width: 991px) {
			padding: 0;
			position: absolute;
			top: 3px;
			right: 10px;
			& > div {
				display: none;
			}
		}
		.navbar-text {
			color: var(--text-darker);
			font-weight: 600;
			margin: 0;
			width: 100%;
			text-align: right;
		}
		a.navbar-text,
    a {
			color: var(--xs-link-color);
			font-size: 24px;
			text-decoration: none;
			display: inline-block;
			&.phone:before {
				content: "\f095";
				font: normal normal normal 14px/1 FontAwesome;
				font-size: inherit;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
			}
			@media (max-width: 991px) {
				float: none !important;
				font-size: 18px;
				@media (max-width: 767px) {
					font-size: 15px;
				}
				i {
					margin: 0 3px;
				}
			}
		}
		.navbar-nav > li {
			display: inline-block;
			white-space: nowrap;
			> a {
				padding: 8px 12px;
				font-size: 15px;
				color: var(--md-header-link-color);
				text-decoration: none;
			}
			&:last-child > a {
				padding-right: 2px;
			}
			&.divider {
				display: inline;
				min-width: 2px;
				min-height: 36px;
				white-space: normal;
				background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAPCAYAAAAlH6X5AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyppVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTMyIDc5LjE1OTI4NCwgMjAxNi8wNC8xOS0xMzoxMzo0MCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUuNSAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDoyRThDNjA4NzAzNzgxMUU3OTNGNEFEM0EwMUY0MzIzQiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDoyRThDNjA4ODAzNzgxMUU3OTNGNEFEM0EwMUY0MzIzQiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjJFOEM2MDg1MDM3ODExRTc5M0Y0QUQzQTAxRjQzMjNCIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjJFOEM2MDg2MDM3ODExRTc5M0Y0QUQzQTAxRjQzMjNCIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+E9zeogAAABpJREFUeNpiWLly5QEmBgYGexDBQAFxECDAANs3A9hrz7agAAAAAElFTkSuQmCC');
				background-position: center center;
				background-repeat: no-repeat;
			}
		}
	}
}

/*
*assets/src/css/hero.css
*/
.header {
	background-color: var(--xs-hero-background);
	background-image: url(/assets/dist/images/hero-bg.jpg);
	background-position: right center;
	background-repeat: no-repeat;
	background-size: 760px 504px;
	color: var(--xs-hero-color);
	@media (min-width: 768px) {
		background-color: var(--sm-hero-background);
	}
	@media (max-width: 991px) {
		text-align: center;
		background: none;
	}
	@media (min-width: 992px) {
		background-color: var(--md-hero-background);
		color: var(--md-hero-color);
	}
	.container {
		@media (max-width: 991px) {
			background-color: var(--sm-hero-container-background);
		}
		@media (min-width: 992px) {
			background-color: var(--md-hero-container-background);
			padding: 0;
			& > .row {
				display: table;
				width: 100%;
				margin: 0;
			}
		}
	}
	.col-md-8 {
		line-height: 22px;
		a {
			color: var(--xs-hero-color);
			@media (min-width: 992px) {
				color: var(--md-hero-color);
			}
		}
		@media (min-width: 992px) {
			padding: 20px 30px 30px;
			display: table-cell;
			float: none;
		}
		h1 {
			font-size: 21px;
			line-height: 24px;
			margin: 13px 10px 12px;
			display: block;
			font-weight: 600;
			widows: 2;
			@media (min-width: 768px) {
				font-size: 26px;
				font-weight: 600;
				margin: 18px 10px;
				line-height: 36px;
			}
			@media (min-width: 992px) {
				margin: 16px 0;
			}
			@media (min-width: 1200px) {
				font-weight: bold;
				margin: 14px 0 24px;
				font-size: 36px;
				line-height: 44px;
			}
		}
	}
	h1 {
		font-size: 22px;
		font-weight: 600;
		margin: 23px 0 16px;
		@media (min-width: 992px) {
			margin-top: 16px;
		}
		@media (min-width: 1200px) {
			font-size: 26px;
		}
	}
	p {
		font-size: 16px;
		line-height: 26px;
		min-height: 1em;
	}
}

/*
*assets/src/css/links.css
*/
.related {
	.list-group-item {
	
		a {
			text-decoration: none;
			font-weight: normal;
		}
	}
}

@media (min-width: 768px) {
	.hasPOS,
  .availableFrom,
  .areaServed,
  .makesOffer,
  .containsPlace {
		background: var(--sm-links-background);
		@media (min-width: 992px) {
			background: var(--md-links-background);
		}
		h2 {
			border-top: 1px solid rgba(0, 0, 0, 0.2);
			color: var(--sm-links-color);
			font-size: 26px;
			padding-top: 35px;
			margin: 0 0 22px;
			& > a.dropdown-toggle {
				color: var(--text);
			}
			.caret {
				color: var(--sm-links-caret-color);
				font-size: 20px;
			}
		}
		.container {
			padding-bottom: 20px;
			@media (min-width: 768px) {
				background: var(--sm-links-container-background);
			}
			@media (min-width: 992px) {
				background: var(--md-links-container-background);
				padding: 0 30px 20px;
			}
		}
		.related {
			padding-left: 26px;
			@media (min-width: 992px) {
				padding-left: 2px;
			}
			@media (min-width: 1200px) {
				padding-left: 12px;
			}
		}
		.list-group {
			padding-top: 0;
			margin-bottom: 14px;
		}
		.list-group-item {
			background: transparent;
		}
	}
}

/*
*assets/src/css/location.css
*/

body > .location {
	@media (max-width: 767px) {
		background: #EAEDEE;
	}
}
body > .location .container > .row > .col-md-5 {
	@media (max-width: 991px) {
		background: #FFFFFF;
	}
}
body > .location .location-detail {
	@media (max-width: 991px) {
		padding: 20px;
	}
}
.location.map {
	.container {

		@media (max-width: 991px) {
			background: #EAEDEE;
			padding: 0 15px;
		}
		
		@media (min-width: 992px) {
			padding: 0 15px;
		}
	}
	#list-panel {
		padding: 12px 10px 0;
		@media (min-width: 768px) {
			background: var(--sm-location-background);
			margin: 0 0 0 15px;
			padding: 0;
			width: 346px;
			min-height: 400px;
		}
		@media (min-width: 992px) {
			width: 374px;
			margin: 0;
		}
		.place {
			text-align: center;
			@media (min-width: 768px) {
				background: transparent;
				padding: 24px 15px 0;
				border: 0;
			}
			@media (min-width: 992px) {
				padding: 22px 15px 0;
			}
			.name {
				@media (min-width: 768px) {
					font-size: 22px;
					margin-bottom: 4px;
				}
			}
			.address {
				@media (min-width: 768px) {
					padding: 25px 15px 0;
				}
				> .hidden-xs {
					font-size: 14px;
					padding-top: 6px;
					display: inline-block;
				}
				.telephone {
					display: inline-block;
					padding-right: 10px;
					border-right: 1px solid #333;
					margin-right: 10px;
					line-height: 1;
					@media (max-width: 767px) {
						border: none;
					}
				}
			}
			.hours {
				@media (min-width: 768px) {
					padding: 25px 0 0;
				}
				strong {
					@media (min-width: 768px) {
						max-width: 75%;
						margin: 2px auto;
					}
				}
			}
			.interact {
				@media (min-width: 768px) {
					padding: 18px 15px 0;
				}
				@media (min-width: 992px) {
					float: none;
				}
				.btn {
					@media (min-width: 768px) {
						padding: 4px 26px;
						display: inline-block;
					}
				}
			}
		}
		.icons {
			background: var(--xs-map-item-background);
			margin: 0 0 15px;
			padding: 5px 20px 15px;
		}
		.telephone,
    .locality {
			@media (max-width: 767px) {
				display: inline-block;
				white-space: nowrap;
				padding: 0 8px;
			}
		}
		.telephone a {
			text-decoration: underline;
		}
		.divider {
			@media (max-width: 767px) {
				display: inline;
				white-space: normal;
				background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAPCAYAAAAlH6X5AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyppVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTMyIDc5LjE1OTI4NCwgMjAxNi8wNC8xOS0xMzoxMzo0MCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUuNSAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo4MzU3QjU2RjE4MTYxMUU3QjIxQjkzRjQxRDMyQkM2QiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo4MzU3QjU3MDE4MTYxMUU3QjIxQjkzRjQxRDMyQkM2QiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjgzNTdCNTZEMTgxNjExRTdCMjFCOTNGNDFEMzJCQzZCIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjgzNTdCNTZFMTgxNjExRTdCMjFCOTNGNDFEMzJCQzZCIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+44vrfgAAABpJREFUeNpi0NTUNGBiYGDoBxEMFBAXAAIMAFp2Aiek9c/1AAAAAElFTkSuQmCC');
				background-position: center center;
				background-repeat: no-repeat;
			}
		}
	}
	#map-panel-holder {
		@media (min-width: 768px) {
			padding: 0;
			width: 374px;
		}
		@media (min-width: 992px) {
			width: 595px;
		}
		@media (min-width: 1200px) {
			width: 795px;
		}
	}
	#location-map {
		@media (min-width: 768px) {
			height: 308px;
		}
	}
	#location-search {
		@media (min-width: 992px) {
			padding: 20px 78px;
		}
		@media (min-width: 1200px) {
			padding: 20px 175px;
		}
	}
	.col-lg-3 {
		padding: 12px 10px 0;
	}
}

/*
*assets/src/css/map-list.css
*/
#location-list {
	p.no-locations {
		background: var(--xs-map-item-background);
		color: var(--xs-map-item-color);
		font-size: 18px;
		padding: 30px;
		text-align: center;
		@media (min-width: 768px) {
			margin: 0;
		}
	}
}
#list-panel {
	padding: 12px 10px 8px;
	position: relative;

	@media (min-width: 320px) {
		padding: 0 5px;
		display: block;
	}
	@media (min-width: 992px) {
		padding: 0;
		width: 470px;
	}
	@media (min-width: 1200px) {
		width: 500px;
	}
	#location-list {
		position: relative;
		min-height: 640px;
		@media (min-width: 320px) and (max-width: 767px) {
			min-height: 310px;
		}
		@media (min-width: 768px) {
			background: var(--sm-map-item-background);
		}
		@media (min-width: 768px) and (max-width: 991px) {
			min-height: 445px;
		}
	}
	#list-loading {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba(0, 0, 0, 0.75);
		min-height: 100%;
		z-index: 9;
		@media (min-width: 320px) and (max-width: 767px) {
			background: var(--secondary);
			min-height: 310px;
		}
		.loader-img {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 50px;
			height: 50px;
			margin: -25px 0 0 -25px;
		}
	}
	.list-group {
		@media (min-width: 768px) {
			overflow: auto;
			max-height: 443px;
			margin: 0;
		}
		@media (min-width: 992px) {
			height: 640px;
			max-height: none;
		}
	}
	.list-group-item {
		border: none;
		&:first-child {
			border-radius: 0;
		}
		&.active:hover {
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		}
	}
	.place {
		background: var(--xs-map-item-background);
		border-radius: 0;
		color: var(--xs-map-item-color);
		font-size: 14px;
		margin: 0;
		padding: 22px 10px 14px;
		text-align: center;
		@media (min-width: 768px) {
			background: var(--sm-map-item-background);
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
			cursor: pointer;
			text-align: left;
			padding: 0 15px 29px;
		}
		@media (min-width: 992px) {
			padding: 0 15px 18px;
		}
		&.active {
			background: var(--sm-map-active-background);
			&:hover {
				color: var(--xs-map-item-color);
			}
		}
		strong {
			color: var(--xs-map-item-strong-color);
			font-weight: 600;
		}
		strong.name {
			display: block;
			font-size: 20px;
			margin-bottom: 10px;
			a {
				font-size: 18px;
			}
		}
		.address {
			@media (min-width: 768px) {
				padding: 25px 15px 0 25px;
			}
			@media (min-width: 992px) {
				padding: 18px 0 10px 30px;
			}
			> .hidden-xs {
				font-size: 13px;
				line-height: 20px;
			}
		}
		.street {
			a {
				text-decoration: none;
			}
		}
		.hours {
			line-height: 17px;
			margin-top: 10px;
			@media (min-width: 768px) {
				font-size: 14px;
				padding: 30px 0 0 50px;
				line-height: 22px;
				margin-top: 0px;
			}
			@media (min-width: 992px) {
				padding: 3px 30px 0;
			}
			strong {
				@media (min-width: 768px) {
					border-bottom: 1px solid rgba(0, 0, 0, 0.1);
					display: block;
					font-size: 14px;
					margin: 0 0 4px;
				}
			}
			dl {
				margin: 0 0 6px;
			}
			dd,
      dt {
				display: inline;
			}
			dt {
				font-weight: normal;
				&::after {
					content: ": ";
				}
			}
		}
		.interact {
			@media (min-width: 768px) {
				padding: 39px 23px 0 50px;
				text-align: center;
			}
			@media (min-width: 992px) {
				padding: 73px 17px 0 5px;
				float: right;
			}
			@media (min-width: 1200px) {
				padding: 73px 18px 0;
			}
		}
		.btn {
			padding: 5px 34px;
			border-radius: 10px;
			font-weight: bold;
			@media (min-width: 768px) {
				display: block;
				padding: 4px 0;
				margin: 0 0 13px;
			}
		}
	}
	.icons {
		padding-top: 18px;
		a {
			font-size: 12px;
			text-decoration: none;
		}
		i {
			display: block;
			font-size: 25px;
		}
	}
	.pager {
		background: var(--xs-map-item-background);
		border-radius: 10px;
		font-size: 15px;
		margin: 10px 0;
		.col-xs-3,
    .col-xs-6 {
			padding: 0;
		}
		a {
			background: var(--xs-map-more-background);
			color: var(--xs-button-color);
			border: 0;
			border-radius: 0;
			font-size: 16px;
			min-width: 75px;
			padding: 9px 14px 8px;
			&.left {
				border-radius: 4px 0 0 4px;
				float: left;
			}
			&.right {
				border-radius: 0 4px 4px 0;
				float: right;
			}
		}
		li.col-xs-6 {
			color: var(--xs-map-more-color);
			line-height: 38px;
		}
	}
}
.location.map {
	#list-panel {
		.place {
			cursor: default;
		}
	}
}

/*
*assets/src/css/map-search.css
*/
:root {
	--btn-width: 56px;
}
#location-search {
	padding: 16px 15px;
	background: var(--xs-search-background);
	@media (min-width: 768px) {
		padding: 20px 15px 20px;
		background: var(--sm-search-background);
		min-height: 92px;
	}
	@media (min-width: 992px) {
		padding: 20px 30px 20px;
	}
	@media (min-width: 1200px) {
		padding: 20px 112px 20px;
	}
	.mapboxgl-ctrl-geocoder {
		border-radius: 0;
		box-shadow: none;
		text-align: left;
		position: relative;
		width: 100%;
		max-width: none;
		min-width: 0;
		&::before {
			display: none;
			line-height: 50px;
			font-size: 16px;
			color: var(--sm-search-color);
			position: absolute;
			text-align: center;
			font-weight: 500;
			width: 144px;
			left: -144px;
		}
		&::after {
			clear: both;
			content: " ";
			display: block;
		}
		@media (min-width: 768px) and (max-width: 991px) {
			max-width: 450px;
			margin: 0 auto;
		}
		input {
			height: 50px;
			font-size: 13px;
			font-family: 'Verdana';
			padding: 0 15px;
			width: calc(100% - var(--btn-width));
			display: block;
			float: left;
		}
		.geocoder-pin-right {
			display: none;
		}
		.geocoder-pin-far-right {
			width: var(--btn-width);
			display: block;
			float: left;
		}
		& > div {
			display: block;
			float: left;
		}
	}
	.btn-search {
		background: var(--xs-search-button-background);
		color: var(--xs-search-button-color);
		border: 1px solid;
		width: var(--btn-width);
		font-size: 20px;
		font-family: OpenSans, 'Open Sans', Arial, sans-serif;
		line-height: 50px;
		font-weight: bold;
	}
}
.location {
	#location-search {
		@media (min-width: 768px) {
			padding: 21px;
		}
		.mapboxgl-ctrl-geocoder {
			@media (min-width: 768px) {
				max-width: none;
				left: 0;
				&::before {
					content: none;
				}
			}
		}
	}
}

/*
*assets/src/css/map.css
*/
.map {
	background: var(--xs-map-background);
	h2 {
		margin: 0 -15px;
		line-height: 1;
		font-size: 16px;
		padding: 0;
		@media (max-width: 991px) {
			padding: 0;
			margin: 0;
		}
		a {
			background: var(--sm-map-anchor-background);
			color: var(--sm-map-anchor-color);
			display: block;
			font-size: 16px;
			line-height: 24px;
			padding: 11px 30px;
			@media (max-width: 767px) {
				padding: 17px 15px;
			}
			.caret {
				color: var(--sm-links-caret-color);
				@media (max-width: 767px) {
					color: var(--xs-drawer-color);
				}
			}
		}
	}
	@media (min-width: 768px) {
		background: var(--sm-map-background);
	}
	@media (min-width: 992px) {
		background: var(--md-map-background);
	}
	.container {
		background: var(--sm-map-container-background);
		@media (min-width: 768px) and (max-width: 991px) {
			padding-bottom: 25px;
		}
	}
}
#map {
	& > .row {
		position: relative;
	}
	.locator-initialized {
		display: none;
	}
	@media (max-width: 767px) {
		display: block;
	}
}
#map-panel-holder {
	@media (max-width: 767px) {
		padding: 0;
	}
	@media (min-width: 992px) {
		padding: 0;
		width: 500px;
	}
	@media (min-width: 1200px) {
		width: 670px;
	}
	&.collapse {
		@media (min-width: 768px) {
			display: block;
		}
	}
}
#location-map {
	background-color: var(--xs-mapbox-background);
	height: 240px;
	@media (min-width: 768px) {
		height: 400px;
	}
	@media (min-width: 992px) {
		height: 548px;
	}
	.mapboxgl-ctrl-compass {
		display: none;
	}
}
.mapboxgl-popup-content {
	padding: 15px 35px 5px;
	box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
	ul {
		list-style-type: none;
		padding: 0;
	}
	li {
		span {
			padding-left: 3px;
		}
	}
}
#start-geolocate {
	background-color: var(--secondary-darker);
	background-image: url(/assets/dist/images/map-blurred.jpg);
	.location-container {
		background: rgba(255, 255, 255, 0.5);
		display: block;
		width: 100%;
		height: 450px;
		@media (min-width: 320px) and (max-width: 767px) {
			height: 300px;
		}
		@media (min-width: 768px) and (max-width: 1199px) {
			height: 450px;
		}
	}
	.location-center {
		display: table-cell;
		padding-top: 30px;
		vertical-align: middle;
		@media (min-width: 320px) and (max-width: 767px) {
			text-align: center;
			padding: 15px 0;
			vertical-align: top;
		}
		.geolocate-loading-icon {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			.loader-img {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 50px;
				height: 50px;
				margin: -25px 0 0 -25px;
			}
		}
		.geolocate-error {
			width: 720px;
			background: none;
			margin: 0 auto 15px;
			@media (min-width: 320px) and (max-width: 767px) {
				padding: 10px;
				width: 300px;
			}
			.geolocate-error-wrapper {
				padding: 24px 20px;
				border-top: 1px solid var(--contrast);
				border-bottom: 1px solid var(--contrast);
				font-size: 20px;
				line-height: 27px;
				font-weight: 700;
				color: var(--text-darkest);
			}
		}
	}
	.geolocate-input {
		width: 720px;
		background: var(--secondary-lightest);
		margin: 0 auto 15px;
		padding: 40px 70px;
		text-align: left;
		@media (min-width: 320px) and (max-width: 767px) {
			padding: 10px;
			width: 300px;
		}
		@media (min-width: 768px) and (max-width: 991px) {
			padding: 40px 55px;
		}
		&::after {
			content: " ";
			display: block;
			clear: both;
		}
		.start-label {
			font-size: 18px;
			font-weight: 700;
			line-height: 24px;
			color: var(--text);
			margin-bottom: 25px;
			@media (min-width: 320px) and (max-width: 767px) {
				text-align: center;
			}
		}
		.location-holder {
			#start-geocoder {
				display: block;
				float: left;
				margin-left: 25px;
				padding-left: 25px;
				border-left: 1px solid #ccc;
				@media (min-width: 320px) and (max-width: 767px) {
					width: 100%;
					float: none;
					margin-left: 0;
					padding-left: 0;
				}
				.mapboxgl-ctrl-geocoder {
					max-width: none;
					background: none;
					width: auto;
					box-shadow: unset;
					&::after {
						content: " ";
						display: block;
						clear: both;
					}
					input {
						display: block;
						float: left;
						background-color: var(--secondary-lightest);
						border: 1px solid #ccc;
						height: 50px;
						width: 240px;
						padding: 18px 15px;
						font-size: 14px;
						line-height: 17px;
						&::placeholder {
							color: #bbb;
						}
						@media (min-width: 320px) and (max-width: 767px) {
							width: 217px;
						}
						@media (min-width: 768px) and (max-width: 991px) {
							width: 260px;
						}
					}
					ul.suggestions {
						top: 50px;
					}
					& > div {
						display: block;
						float: left;
						.geocoder-pin-right {
							display: none;
						}
						.btn-search {
							display: block;
							height: 50px;
							background-color: var(--contrast);
							color: var(--text-lightest);
							padding: 14px;
							border: 1px solid #fff;
							font-size: 20px;
							line-height: 1;
							font-weight: 700;
						}
					}
				}
			}
			.start-separator {
				display: none;
				float: left;
				padding: 10px 0;
				@media (min-width: 320px) and (max-width: 767px) {
					display: none;
				}
				&::before {
					content: "OR";
					padding: 0 35px;
					color: var(--text-lightest);
					font-size: 20px;
					line-height: 28px;
					font-weight: 700;
				}
			}
			a.btn {
				display: block;
				float: left;
				font-size: 14px;
				font-weight: 700;
				line-height: 1;
				background-color: var(--contrast);
				padding: 18px;
				border: 1px solid #fff;
				border-radius: 0;
				@media (min-width: 320px) and (max-width: 767px) {
					margin: 20px auto;
					float: none;
					width: 140px;
				}
				&::before {
					content: "\f124";
					font: normal normal normal 19px/14px FontAwesome !important;
					font-size: inherit;
					text-rendering: auto;
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
					margin: 0 5px 0 0;
				}
			}
		}
	}
}

/*
*assets/src/css/menu.css
*/
@media (max-width: 991px) {
	.navbar-header {
		float: none;
	}
	.navbar-left,
  .navbar-right {
		float: none !important;
	}
	.navbar-toggle {
		display: block;
	}
	.navbar-collapse {
		border-top: 1px solid transparent;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
	}
	.navbar-fixed-top {
		top: 0;
		border-width: 0 0 1px;
	}
	.navbar-collapse.collapse {
		display: none !important;
	}
	.navbar-nav {
		float: none !important;
		margin-top: 7.5px;
	}
	.navbar-nav > li {
		float: none;
	}
	.navbar-nav > li > a {
		padding-top: 10px;
		padding-bottom: 10px;
	}
	.collapse.in {
		display: block !important;
	}
}
.header {
	.container {
		position: relative;
	}
	.navbar-toggle {
		padding: 11px 12px 12px 13px;
		background: var(--xs-menu-background);
		z-index: 11;
		@media (min-width: 768px) {
			position: absolute;
			top: 0;
			left: 0;
		}
		&:focus {
			background: var(--xs-menu-background);
		}
		&.collapsed {
			background: var(--xs-hamburger-background);
		}
		.icon-bar {
			background: var(--xs-hamburger-color);
			width: 25px;
		}
	}
	.close {
		display: none;
	}
}
.navbar-collapse {
	@media (max-width: 768px) {
		box-shadow: 0 8px 5px -2px rgba(0, 0, 0, 0.2);
		padding: 41px 0 0;
	}
	@media (max-width: 991px) {
		position: absolute;
		top: 0;
		width: 100%;
		text-align: center;
		z-index: 10;
	}
	@media (min-width: 768px) and (max-width: 991px) {
		background: transparent;
		padding: 0;
		margin: 0;
		overflow: hidden;
		box-shadow: none;
	}
	@media (min-width: 992px) {
		padding: 0;
	}
	ul.navbar-nav {
		margin: 0 auto;
	
		.caret {
			@media (min-width: 992px) {
				font-size: 14px;
				line-height: 46px;
			}
			&::before {
				content: "\f078";
			}
		}
		.open {
			background: var(--xs-menu-item-background);
			.caret::before {
				content: "\f077";
			}
			.dropdown-menu {
				text-align: center;
				padding: 0;
				.divider {
					background: var(--xs-menu-background);
					margin: 0;
					height: 9px;
				}
			}
		}
		.open > a,
    .open > a:focus,
    .open .dropdown-menu > li > a,
    & > li > a,
    & > li > a:focus {
			background: var(--xs-menu-item-background);
			border-top: 0;
			font-size: 16px;
			font-weight: 600;
			line-height: 59px;
			padding: 0;
			text-decoration: none;
			@media (min-width: 768px) {
				padding: 0 55px;
			}
			@media (min-width: 992px) {
				padding: 0 20px;
				line-height: 48px;
			}
			@media (min-width: 1200px) {
				padding: 0 35px;
				line-height: 50px;
				border: 0;
			}
			&:hover {
				background: var(--secondary-darkest);
				color: var(--xs-menu-item-color);
			}
		}
		.open a:focus,
    & > li > a,
    & > li > a:focus {
			@media (min-width: 992px) {
				border-right: 1px solid rgba(255, 255, 255, 0.0);
			}
		}
		.dropdown-menu {
			@media (min-width: 768px) and (max-width: 992px) {
				width: 100%;
			}
		}
		li.close {
			display: block;
			float: none;
			line-height: initial;
			opacity: 1;
			position: absolute;
			right: 0;
			top: 0;
			@media (min-width: 768px) and (max-width: 991px) {
				z-index: 4;
			}
			> a:hover,
      > a {
				background: var(--xs-hamburger-background);
				border: 0;
				color: var(--xs-hamburger-color);
				width: 50px;
				line-height: 41px;
				text-shadow: none;
				font-size: 22px;
				&:hover {
					background: var(--xs-hamburger-background);
					color: var(--xs-hamburger-color);
				}
				@media (min-width: 768px) {
					padding: 0;
				}
			}
		}
	}
}

/*
*assets/src/css/root.css
*/
body {
	font-family: OpenSans, 'Open Sans', Arial, sans-serif;
}
.btn {
	border: 0;
	border-radius: 10px;
}
.btn-danger {
	background: var(--xs-button-background);
	color: var(--xs-button-color);
	&:hover,&:focus,&:active {
		background: var(--xs-button-background);
	}
}
@media (max-width: 767px) {
	.container {
		> h2 {
			> a.dropdown-toggle {
				padding: 17px 15px;
			}
		}
	}
}

/*
* DYNAMIC APPENDS 
*/
root: {
	-webkit-font-smoothing: antialiased;
	font-weight: normal;
}



#start-geolocate {
	height:300px;
	min-width: 100%;
	display: block;
	&::after {
		top:0px;
		bottom: 0px;
	};
	@media (min-width: 768px) {
		height:450px;
	}
	.geolocate {
		height: 100%;
		text-align: center;
		background:rgba(255,255,255,.5);
	}
	.location-center {
		display: inline-block;
	}
	.geolocate-input {
		.location-holder {
				a.btn {
					border-radius: 0;
					color: var(--text-lightest) !important;	
				} 
		}
	}
	.geocoder-pin-far-right {
		.btn-search {
				border-radius: 0;
		}
		.btn-search::before {
			content: '';
			line-height: 0px;
  		display: none;
		}
	}
	
}

#location-search .btn-search {
	border-radius: 0;
	padding: 0;
}

#location-search .btn-search::before {
	content: '';
	line-height: 0px;
  display: none;
}

#location-search .mapboxgl-ctrl-geocoder input {
	background: #FFFFFF;
	border: 1px solid #FFFFFF;
	min-height: 52px;
}

.body-cta {
	.btn-danger {
			color: var(--primary) !important;
			font-weight: 700;
			text-transform: none;
	}
}

.header {
	display: block;
	.col-md-8  h1 {
		font-weight: 600;
	}
	.cta {
		strong {
			font-weight: 600;
		}
		.btn {
			padding: 20px 20px;
			font-weight: 700;
			text-transform: none;
			color: var(--text-lightest) !important;
			@media (max-width: 992px) {
			 	color: var(--primary) !important;

			}
		}
		.btn:hover {
			color: var(--text-lightest) !important;
			@media (max-width: 992px) {
				color: var(--primary) !important;
			}	
		}
	}
}

#list-panel .place .btn,
body > .location .location-detail .btn {
	background: var(--primary) !important;
	color: var(--text-lightest) !important;
	font-weight: 700;
	text-transform: none;
	padding: 20px 10px;
	width: auto;
	min-width: 150px;
}

#list-panel .place > .row > .col-md-5 {
	width: 100%;
	@media (min-width: 992px) {
		width: auto;
	}
}

#list-panel .place .address {
	text-align: center;
	@media (min-width: 992px) {
		text-align: left;
	}
}

#list-panel .place .hours {
	@media (max-width: 991px) {
		padding-left: 0;
		padding-right: 0;
		width: 100%;
		text-align: center;
	}
}

#list-panel .place .links {
	padding-left: 30px;
}
.body-cta p {
	margin-right: 20px;
}

.content .container h2 {
	color: #6a737b !important;
	font-weight: 600;
	margin-top: 0px !important;
}

@media (min-width: 992px) {
	.content .container .btn-holder {
		margin-bottom: 0px;
	}
	.footer .container .row .col-md-6:nth-child(1n) {
		width: 80% !important;
	}

	.navbar-collapse ul.navbar-nav > li > a {
		height: 50px;
	}

	.navbar-collapse ul.navbar-nav {

		a {
			padding-top: 0;
			padding-left: 10px;
			border-right-width: 0;
			font-weight: normal !important;
		}
		.caret {
			display: inline;
			&::before {
				content: "";
				display: none;
			}
			&::after {
				content: "\f078";
				font-family: 'FontAwesome';
			}
		}
		.open {
			color: var(--text-lightest);
			.caret {
				&::before {
					content: "";
					display: none;
				}
				&::after {
					content: "\f077";
					font-family: 'FontAwesome';
				}
			}
		}

		.open > a,
    .open > a:focus,
    .open .dropdown-menu > li > a,
    & > li > a,
    & > li:not(.menu-brand) > a:focus {
       padding: 0 28px;
       border-right-width: 0;

      @media (min-width: 992px) {
        padding: 0 28px;
      }

      @media (min-width: 1200px) {
       	padding: 0 28px;
      }

      &:hover {
      	color: var(--text-lightest);
      	border-right-width: 0;
      }
    }
	}
}


.footer ul.disclaimers {
	margin-top: -60px;

}

#list-panel {

 .place .interact {
 	padding: 20px 30px;
 }	
 .list-location-secondary-anchor {
 	 margin-top: 0px;
 }	
 a {
 	color: var(--primary) !important;
 }

 a:hover {
 	 color: var(--text-darker) !important;
 }	
}



/*** CUSTOM ****/


.btn, 
.btn-search,
.btn-danger, 
.header .cta .btn,
#list-panel .place .btn, 
#location-search .btn-search,
body > .location .location-detail .btn,
#start-geolocate .geolocate-input .location-holder #start-geocoder .mapboxgl-ctrl-geocoder > div .btn-search,
#list-panel .pager a.right, #list-panel .pager a.left {
	&:hover {
		background: var(--secondary-darkest) !important;
		color: var(--text-lightest) !important;
	}
	&:active {
		background:#ac2925 !important;
	}
}

.top {
	max-height: 138px;
}


.team .container .team-grid .team-item .team-picture {
	background: transparent;
  height: 160px;
  width: 160px;
  @media (min-width: 992px) {
  	height: 200px;
  	width: 200px;
  }
}

.team .container .team-grid .team-item .team-name {
	color: #6D737A;
}

.team .container .team-grid .team-item .phone a::before {
	font-family: FontAwesome;
  content: "\f095";
  display: inline-block;
  text-decoration: none;
  padding-right: 3px;
  vertical-align: bottom;
}



.team .container .team-grid .team-item .phone {
	padding-top: 10px;
	a {
		color: #0088CD;
		font-family: "Open Sans";
		font-size: 15px;
		line-height: 17px;
		text-decoration: none;
	}
}

.team .container .team-more { 
		background-color: #0088CD;
		.team-view-more  {
			 span, i.fa {
			 	color: #FFFFFF;
			 	text-transform: none;
			 }
		}
		
}

.top a.phone {
	font-weight: 600;
	height: 20px;
}

.top .navbar-right.hidden-xs {
	float: none !important;
	padding: 0;
	width: 100%;
	height: 30px;
	position: absolute;
	margin-top: -65px;
	padding-right: 65px !important;
}
.top .navbar-right.phone {
	float: right !important;
	margin-top: 0px;
	padding-top: 30px;
	position: relative;

}
.body-cta .container {
	background: none;
}

.header .cta {
	padding: inherit;
}

.content .btn.btn-danger {
	background: var(--xs-button-background);
	border: 2px solid;
	border-radius: 10px;
	padding: 20px 10px;
  font-weight: 700;
  text-transform: none;
  color: #fff !important;
	font-size: 18px;
	width: 196px;
	@media(max-width: 768px) {
		width: 170px;
	}
	&:hover,
	&:active,
	&:focus {
		background: #6a737b !important;
	  color: #fff !important;
	}
}

.footer ol.breadcrumb {
	margin: 0 0 30px; 
}

.content .container p a {
	color: #0088cd;
	
}
.content .container p a:hover, .content .container p a:focus {
	color: #337ab7;
};

#list-panel .pager li.col-xs-6 {
	height: 50px;
	padding: 5px 0px;
}

#list-panel .pager a.right, #list-panel .pager a.left {
	height: 50px;
	color: #FFFFFF !important;
	padding-top: 17px;
}

.footer .menu ul > li, .footer ul.disclaimers > li {
		padding-bottom: 20px;
	  display: inline-block;
    white-space: pre-wrap;
    float: none;
}

.top {
	@media(max-width: 991px) {
		background: #FFFFFF;
	}
}

.top .container {
	@media(max-width: 991px) {
		background: #FFFFFF;
		padding-bottom: 0px;
		height: 120px;
	}	

	@media(max-width: 510px) {
		height: 72px;
	}
}

.top .navbar-header.phone .logos {
	@media(max-width: 991px) {
		width: 95%;
		text-align: center;
		margin: inherit;
		padding-top: 0px;
		padding-bottom: 0px;
	}
	@media(max-width: 767px) {
		padding-top: 22px;
	}
	
}

.top .navbar-header .navbar-brand {
	@media(max-width: 510px) {
		height: 37px;
	}
}
.header-sm h1 {
	@media(max-width: 991px) {
		height: 70px;
	}	
	@media(max-width: 767px) {
		height: 50px;
	}
}


.header-sm .container {
	@media(max-width: 767px) {
			padding-top: 10px;
	}
	@media(max-width: 991px) {
		background: #EAEDEE;
	}	
}

.top .navbar-right.phone{
	@media(max-width: 991px) {
		position: absolute;
	}

	@media(max-width: 510px) {
		top: -40px;
		right: 1px;
	}
}

.locator .container {
	@media(max-width: 991px) {
		background: #EAEDEE;
	}
}

#list-panel .place {
	@media(max-width: 991px) {
		background: #FFFFFF;
	}
}

.content .container #header-content {
	@media(min-width: 768px) {
		margin-top: 40px;
	}
}
.content .container .btn-holder {
	@media(max-width: 991px) {
		margin-bottom: 0px;
	}
}

.content .container h2 {
	@media(max-width: 991px) {
		padding-bottom: inherit;
	}

	@media(max-width: 767px) {
		padding-bottom: 5px;
	}
}

.hasPOS h2 > a.dropdown-toggle, 
.availableFrom h2 > a.dropdown-toggle, 
.areaServed h2 > a.dropdown-toggle, 
.makesOffer h2 > a.dropdown-toggle, 
.containsPlace h2 > a.dropdown-toggle {
	font-weight: normal;
}

#list-panel .icons {
	@media (min-width: 768px) and (max-width: 991px) {
		padding-left: 100px;
	}
}

body > .location .icons a {
    color: #0088cd !important;
}

body > .location .icons a:hover {
    color: #4a4a4a !important;
}


